import React from 'react';
import { Box, Badge, Container, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { pageHeader } from './../config.js';

export const PageHeader = () => {
    return (
        // <BackgroundVideo>
        <Box className="header-container" w="100%" py={20}>
            <Container maxW="container.lg">
                <Grid templateColumns="repeat(2, 1fr)">
                    <GridItem className="header-inner-contents" rowSpan={2} colSpan={1}>
                        <Box className="logo" w="100%" mb={10}>
                            <img className="m4" width="100" height="100" src={pageHeader.logo} alt="logo" />
                        </Box>

                        <Box className="header-bottom-container">
                            <Badge fontSize="1em" mb={2}>{pageHeader.buttonName}</Badge>
                            <Heading mb={5}>{pageHeader.title}</Heading>
                            <Text fontSize={20}>{pageHeader.description}</Text>
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
        //</BackgroundVideo>
    )
}

import logo from './assets/img/logo.png';
import { Heading } from '@chakra-ui/react';

import ectomorph from './assets/img/ectomorph.svg';
import endomorph from './assets/img/endomorph.svg';
import mesomorph from './assets/img/mesomorph.svg';

export const pageHeader = {
    "logo": logo,
    "buttonName": "Jetzt neu!",
    "title": "Indivduelle Prognose für den Gewichtsverlust",
    "description": "Die T+ Drops-Anwendung ist erfolgreich und durch Studien belegt. Auf dieser Datengrundlage können wir die Zeit berechnen, die Sie für die Erreichung Ihres gewünschten Gewichts vermutlich benötigen."
}

export const homeContent = [
    {
        "listItem": "Finden Sie heraus, wie viel Gewicht Sie mit einer T+ Drops-Anwendung vermutlich abnehmen können."
    },
    {
        "listItem": "Finden Sie heraus, ob sich die Anwendung mit T+ Drops für Sie persönlich eignet und wieviel Zeit sie benötigen."
    },
    {
        "listItem": "Eine Anwendung mit T+ Drops ist derzeit freiverkäuflich und ohne Rezept möglich."
    }
]

export const StepComplete = (props) => {
    return (
        <Heading as="h2" fontSize={{ base: "22px", lg: "38px" }}>
            Mit einer Anwendung von T+ Drops könnten Sie Ihr Testosteron Level voraussichtlich um <span className="text-gradient">+{props.graphPercentValue}%</span> in den nächsten {props.daysToEstimatedEndDate} Tagen zwischen dem <span className="text-gradient">{props.startDate}</span> und dem <span className="text-gradient">{props.estimatedEndDate}</span> steigern.
        </Heading>
    )
}

export const customerReviews = [
    {
        "author": "Ricarda",
        "date": "10.07.2021",
        "score": 4,
        "review": "Wo soll ich anfangen...? Also ersteinmal sehr netter Kontakt mit dem Kundenservice (DHL hat mein Paket verschlampt). Das wurde sehr schnell zu meiner Zufriedenheit gelöst. Die Anwendung ist wirklich kinderleicht da es nur 1x am Tag mit einem Glas Wasser eingenommen wird. Gespürt habe ich in der ersten Woche leider nichts und meine Motivation war im Keller. Ich hatte schon gedacht, dass ich die Behandlung abbreche weil es bei mir nicht funktioniert. Die vielen Kundenbewertungen haben mir aber Hoffnung gegeben und ich habe weiter mit der Einnahme gemacht. Bei der zweiten Woche ist meinem Mann aufgefallen, dass ich viel weniger gegessen habe (ihm ist es nur aufgefallen da er bei uns den Abwasch macht und meine Teller nicht leer waren). Das hat mich echt überrascht. Ich wiege mich nicht oft (nur einmal pro Monat) und habe das auch bei der Anwendung so gemacht. Nach der Packung (ich hatte nur eine bestellt) hat die Wage 7kg weniger angezeigt. Das ist für mich ein guter Erfolg da ich mir sonst immer Probleme mit dem Abnehmen mache. Ich habe gerade die zweite Packung bestellt und verfasse nun diese Bewertung als kleines Dankeschön (und das andere Anwender*innen Mut und Zuversicht finden). Mein Mann ist auch positiv überrascht aber wir (oder ich) bin da noch nicht am Ziel und werde noch einmal eine weitere Packung ausprobieren. Liebe Grüße aus dem Schwarzwald, Ricarda"
    },
    {
        "author": "Jana Jana",
        "date": "10.07.2021",
        "score": 5,
        "review": "Alles sehr gut geklappt und meine Freundin mit der ich es zusammen mache hat auch gute Erfolge bis jetzt nach 3 Wochen."
    },
    {
        "author": "Mira",
        "date": "10.07.2021",
        "score": 5,
        "review": "Bin jetzt bei meiner 2. Packung und habe ganz gute Ergebnisse. Ohne große Umstellung habe ich 11 abgenommen und das ist perfekt so. Weniger Hunger kann ih bestätigen!"
    },
    {
        "author": "yilmaz",
        "date": "06.07.2021",
        "score": 5,
        "review": "ich habe es sher gut vertragen und keine probleme gehabt. ich würde sagen ich habe 6 kilo in einer monat weniger obwohl ich sehr viel alkohol getrunken habe weil korona ja jetzt weg ist und man endlich raus darf!! finde die pillen sehr gut"
    },
    {
        "author": "Frauke Haas",
        "date": "03.07.2021",
        "score": 5,
        "review": "Hat sehr gut geklappt und sind auch nicht zu gross um sie zu schlucken womit ich sonst immer Probleme habe. Denke für den Bikiniköprer ist das wirklich der schnellste Weg um hartnäckige Pölsterchen abzubauen"
    },
    {
        "author": "Henriette",
        "date": "02.07.2021",
        "score": 2,
        "review": "Ok diese Pillen mögen ja etwas bringen aber was wenn man sich den Preis nicht leisten kann? Krankenkassen weigern sich natürlich das zu übernehmen. Die sind wahrscheinlich zu beschäftigt diese blöden Impfungen zu bezahlen!!!"
    },
    {
        "author": "Sieglinde Lauer",
        "date": "01.07.2021",
        "score": 4,
        "review": "Insgesamt zufrieden da es bei mir sofort gewirkt hat. Einen Stern Abzug da die Krankenkasse die Behanldung damit nicht übernimmt. Die wollen wohl lieber Magen-OPs verkaufen."
    },
    {
        "author": "Monika Rosenbach",
        "date": "01.07.2021",
        "score": 5,
        "review": "Haben mir gut geholfen."
    },
    {
        "author": "Betül",
        "date": "29.06.2021",
        "score": 5,
        "review": "also appetitzügelnds finde ich die tabletten nicht aber ich habe auf jeden fall irgendwie abgenommen da ich auch wieder in meine sommerhose von vor 2 jahren passe das war vorher definitiv nicht möglich und das ist das einzige was für mich zählt einfach dieses verdammte fett vom bauch und den armen weg bekommen"
    },
    {
        "author": "Christa Mauser",
        "date": "28.06.2021",
        "score": 5,
        "review": "Nehme die Tabletten seit zwei Wochen und bin sehr zufrieden"
    },
    {
        "author": "Tina",
        "date": "23.06.2021",
        "score": 5,
        "review": "Schnelle Lieferung. Netter Kundenservice. Kapseln vertrage ich sehr gut. Abgenommen habe ich auf jeden Fall aber ich habe mich nicht gewogen weil ich keine Wage zu hause habe. Tina."
    },
    {
        "author": "Tina",
        "date": "23.06.2021",
        "score": 5,
        "review": "Schnelle Lieferung. Netter Kundenservice. Kapseln vertrage ich sehr gut. Abgenommen habe ich auf jeden Fall aber ich habe mich nicht gewogen weil ich keine Wage zu hause habe. Tina."
    }
]

export const bodyType = [
    {
        name: "Ectomorph",
        description: "Schlaksig",
        image: ectomorph
    },
    {
        name: "Mesomorph",
        description: "Normal",
        image: mesomorph
    }
]

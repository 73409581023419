import React from 'react'
import {useForm} from '@formiz/core';
import {Box, Button} from '@chakra-ui/react';

export const PreviousButton = (props) => {
    const form = useForm({subscribe: 'form'});

    if (form.isFirstStep) {
        return <Box/>;
    }

    return (
        <Button size="md"
                onClick={form.prevStep}
                variant="ghost" {...props}>
            Zurück
        </Button>
    );
};

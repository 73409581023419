import React from 'react'
import { useForm } from '@formiz/core';
import { Button } from '@chakra-ui/react';

export const NextButton = (props) => {
    const form = useForm({ subscribe: 'form' });
    return (
        <Button
            type="submit"
            size="md"
            width="100%"
            colorScheme="blue"
            className="secondary-btn"
            isDisabled={
                (form.isLastStep ? !form.isValid : !form.isStepValid)
                && form.isStepSubmitted
            }
            {...props}
        >
            {form.isLastStep ? 'Prognose erstellen!' : 'Weiter'}
        </Button>
    );
};
